<template>
  <v-container>
    <v-card flat>
      <v-card-title class="headline">
        Downloads
      </v-card-title>
      <v-card-text>
        <v-list-item v-if="latestRelease">
          <v-list-item-content>
            <v-list-item-title>
              Mammut OS {{ latestRelease.tag_name }} Windows Setup
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ latestReleaseSetup.size | formatBytes }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              :href="latestReleaseSetup.browser_download_url"
              outlined
            >
              <v-icon left>
                mdi-download
              </v-icon>
              Download
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Downloads',
  data: () => ({
    latestRelease: null,
    latestReleaseSetup: null
  }),
  async beforeMount() {
    const { data } = await this.$axios.get('https://api.github.com/repos/Mammut-OS/mammut-os-win/releases/latest')
    this.latestRelease = data
    this.latestReleaseSetup = data.assets.find(x => x.name === 'Mammut-OS-Setup.exe')
  }
}
</script>
